import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(std)/(static)": [9,[2]],
		"/(std)/(static)/about-us": [11,[2]],
		"/(std)/(static)/about/[slug]": [~10,[2]],
		"/(std)/account": [~19],
		"/admin/books": [~40,[7]],
		"/admin/books/[book_id]": [~41,[7]],
		"/admin/pages": [~42,[7]],
		"/admin/pages/[page_id]": [~43,[7]],
		"/admin/users": [~44,[7]],
		"/admin/users/[user_id]": [~45,[7]],
		"/(std)/books": [20,[3]],
		"/(std)/books/[book_id]": [21,[3,4]],
		"/(std)/books/[book_id]/accept/[invite_id]": [22,[3,4]],
		"/(std)/books/[book_id]/annotations": [23,[3,4]],
		"/(std)/books/[book_id]/bibliography": [24,[3,4]],
		"/(std)/books/[book_id]/chapters/[chapter_id]": [25,[3,4]],
		"/(std)/books/[book_id]/chapters/[chapter_id]/debug": [26,[3,4]],
		"/(std)/books/[book_id]/chapters/[chapter_id]/debug/[version_number]": [~27,[3,4]],
		"/(std)/books/[book_id]/debug/diagnose": [28,[3,4,5]],
		"/(std)/books/[book_id]/debug/mapping": [29,[3,4,5]],
		"/(std)/books/[book_id]/debug/math": [30,[3,4,5]],
		"/(std)/books/[book_id]/debug/math/[eq]": [31,[3,4,5]],
		"/(std)/books/[book_id]/debug/narrow": [32,[3,4,5]],
		"/(std)/books/[book_id]/debug/properties": [33,[3,4,5]],
		"/(std)/books/[book_id]/debug/typst": [34,[3,4,5]],
		"/(std)/books/[book_id]/index": [35,[3,4]],
		"/(std)/books/[book_id]/notes": [36,[3,4]],
		"/(std)/books/[book_id]/title": [37,[3,4]],
		"/(std)/books/[book_id]/toc": [38,[3,4]],
		"/(std)/(static)/contact": [~12,[2]],
		"/(std)/(static)/pricing": [13,[2]],
		"/(std)/(static)/privacy": [14,[2]],
		"/(std)/settings/link/finished": [39,[6]],
		"/(std)/(static)/sign-in": [15,[2]],
		"/(std)/(static)/terms": [16,[2]],
		"/(std)/(static)/tutorial": [~17,[2]],
		"/(std)/(static)/tutorial/[slug]": [~18,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';